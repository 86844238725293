import { css } from 'styled-components';

export const normalizeButton = css`
  appearance: none;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
`;

export const fullHeightMobile = css`
  height: 100vh;
  height: stretch;
  height: -moz-available;
  height: -webkit-fill-available;
`;

export const minFullHeightMobile = css`
  min-height: 100vh;
  min-height: stretch;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
`;

export const maxFullHeightMobile = css`
  max-height: 100vh;
  max-height: stretch;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
`;

export const absoluteFill = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

export const selectableTextCss = css`
  -webkit-touch-callout: default !important;
  -webkit-tap-highlight-color: initial !important;
  -webkit-user-drag: initial !important;
  user-select: text !important;

  & * {
    -webkit-touch-callout: inherit;
    -webkit-tap-highlight-color: inherit;
    -webkit-user-drag: inherit;
    user-select: inherit;
  }
`;
